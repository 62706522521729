import { forwardRef, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import {
  MESSAGE_STATUS,
  PaymentusSetupItemTypeName,
  ProcessPaymentPaymentusRequestData,
} from "@ftdr/payment-method-micro-frontend";
import { GenericInput, GenericInputProp } from "./components";
import { usePaymentusForm } from "./use-paymentus-form";
import {
  accountNumberError,
  accountNumberConfirmationError,
  routingNumberError,
} from "./const";
import { payment3_paymentmethodpb } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { usePaymentMethodClientContext } from "../config/contexts/payment-method-client-context";
import { PaymentRootState } from "../config/store";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { DefaultPaymentusStyles } from "../const";

export type PaymentusFormRef = {
  submitForm: (requestData?: ProcessPaymentPaymentusRequestData) => void;
};

export const PaymentusFormInputsConfig: Record<
  PaymentusSetupItemTypeName,
  Omit<GenericInputProp, "name" | "row" | "label">
> = {
  bankRoutingNumber: {
    minLength: 9,
    maxLength: 9,
    errorTypes: routingNumberError,
    onlyDigits: true,
  },
  bankAccountNumber: {
    minLength: 4,
    maxLength: 17,
    errorTypes: accountNumberError,
    onlyDigits: true,
  },
  bankAccountNumberConfirmation: {
    errorTypes: accountNumberConfirmationError,
    onlyDigits: true,
    matchValue: "bankAccountNumber",
  },
  saveInWallet: {
    errorTypes: {},
  },
};

export const PaymentusForm = forwardRef<PaymentusFormRef | undefined>(
  (_, ref) => {
    const {
      state,
      methods,
      handleCreatePaymentusPaymentMethod,
      elementsConfig,
    } = usePaymentusForm();
    const { createPaymentMethodRegistration } = usePaymentMethodClientContext();
    const { styleVariant } = useSelector(
      (state: PaymentRootState) => state.paymentState
    );

    useImperativeHandle(ref, () => ({
      submitForm: async (requestData) => {
        const result = await handleCreatePaymentusPaymentMethod(
          requestData,
          state.registrationResponse?.registration?.paymentus?.authSSOToken,
          state.registrationResponse?.registration?.registrationID
        );

        switch (result?.status) {
          case MESSAGE_STATUS.CREATE_PAYMENT_METHOD_REDIS_CACHE_ERROR:
            const registrationResult = await createPaymentMethodRegistration({
              paymentMethod: {
                details: {
                  type: payment3_paymentmethodpb.PaymentMethodType.ACH,
                },
              },
            });

            await handleCreatePaymentusPaymentMethod(
              requestData,
              registrationResult?.registration?.paymentus?.authSSOToken,
              registrationResult?.registration?.registrationID
            );
            break;
        }
      },
    }));

    return (
      <FormProvider {...methods}>
        <form>
          {elementsConfig.map((row, index) => {
            return (
              <div
                data-styleid="PaymentusInputsContainer"
                key={index}
                className={classNames(
                  DefaultPaymentusStyles[styleVariant].PaymentusInputsContainer,
                  "paymentusInputsContainer"
                )}
              >
                {row.map(
                  ({ name, label, placeholder, className }) =>
                    name && (
                      <GenericInput
                        key={name}
                        name={name}
                        className={className}
                        label={label}
                        placeholder={placeholder}
                        {...PaymentusFormInputsConfig[name]}
                      />
                    )
                )}
              </div>
            );
          })}
        </form>
      </FormProvider>
    );
  }
);

export default PaymentusForm;
