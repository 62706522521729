import { useAppContext } from "@ftdr/blueprint-components-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  PaymentRootState,
  setError,
  setIframeMode,
  useAppDispatch,
} from "../../config/store";
import { HostModes, HostModesValue } from "../../types";
import { useSelector } from "react-redux";

export const useIframeMode = () => {
  const [searchParams] = useSearchParams();
  const iframeMode = (searchParams.get("mode") ||
    HostModesValue.WEB) as HostModes;
  const dispatch = useAppDispatch();
  const storeIframeMode = useSelector(
    (state: PaymentRootState) => state.paymentState.iframeMode
  );
  const {
    appSettings: { localizedText },
  } = useAppContext();
  useEffect(() => {
    if (Object.values(HostModesValue).includes(iframeMode)) {
      dispatch(setIframeMode(iframeMode));
    } else {
      dispatch(
        setError({
          isError: true,
          message: localizedText(`WRONG_MODE_ERROR`),
        })
      );
    }
  }, [iframeMode, dispatch, localizedText]);

  return { storeIframeMode, iframeMode };
};
