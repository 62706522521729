import { ProtobufHttpClient } from "@ftdr/http-utils";
import { Payment3_paymentmethod_coordinatorJSClient } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { Payment3_paymentus_secureJSClient } from "@ftdr/payment3_paymentus_secure-js-client";
import { Mixin } from "ts-mixer";
import { UuidType } from "../../../store";

export class DebugIdentifiers {
  protected httpClient: ProtobufHttpClient;
  constructor(_baseUrl: string, httpClient: ProtobufHttpClient) {
    this.httpClient = httpClient;
  }

  setDebugIdentifiers = ({ traceId, spanId }: UuidType): void => {
    this.httpClient.defaults = {
      ...this.httpClient.defaults,
      headers: {
        ...this.httpClient.defaults.headers,
        common: {
          ...this.httpClient.defaults.headers,
          "x-b3-traceid": traceId,
          "x-b3-spanid": spanId,
        },
      },
    };
    this.httpClient.mergeDefaults();
  };
}

export class PaymentMethodCoordinatorJSClient extends Mixin(
  DebugIdentifiers,
  Payment3_paymentmethod_coordinatorJSClient
) {
  constructor(baseUrl: string, httpClient: ProtobufHttpClient) {
    super(baseUrl, httpClient);
    this.httpClient = httpClient;
  }
}

export class PaymentusSecureJSClient extends Mixin(
  DebugIdentifiers,
  Payment3_paymentus_secureJSClient
) {
  constructor(baseUrl: string, httpClient: ProtobufHttpClient) {
    super(baseUrl, httpClient);
    this.httpClient = httpClient;
  }
}
