import {
  PaymentusDefaultSetup,
  PaymentusSetupItemType,
  PaymentusSetupItemTypeName,
  PaymentusSetupType,
} from "@ftdr/payment-method-micro-frontend";

export const REQUIRED_PROPERTIES: PaymentusSetupItemTypeName[] = [
  "bankAccountNumber",
  "bankRoutingNumber",
];

export const InitialSetupItem: PaymentusSetupItemType = {
  row: -1,
  label: "",
};

type ReturnType = Array<Array<PaymentusSetupItemType>>;

export const generateSetupArray = (
  setup: PaymentusSetupType = PaymentusDefaultSetup
) => {
  const setupKeys = Object.keys(setup) as Array<keyof typeof setup>;
  if (
    !REQUIRED_PROPERTIES.every((requiredProperty) =>
      setupKeys.includes(requiredProperty)
    )
  ) {
    throw Error(
      "Required properties missing (bankAccountNumber,bankRoutingNumber)"
    );
  }

  return setupKeys.reduce<ReturnType>((acc, key) => {
    const setupItem: PaymentusSetupItemType = setup[key] || InitialSetupItem;
    acc[setupItem?.row] = [
      ...(acc[setupItem?.row] || []),
      {
        ...setupItem,
        name: key,
      },
    ];
    return acc;
  }, []);
};

export const removeNonDigits = (text: string) => {
  return text.replace(/[^0-9]/g, "");
};
