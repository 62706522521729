import React, { FC } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
// @ts-ignore
import { PaymentusHostedIframe } from "./paymentus-hosted-iframe";
import { EnvironmentsDataType } from "./config/environment";
import { App } from "@ftdr/blueprint-components-react";
import { enTextTemplates } from "./assets/en-text-templates";
import { setStyleType, setStyleVariant, store } from "./config/store";
import { IframeResizer, IframeModeChecker } from "./components";
import { ENV_URL_MAP } from "./const";
import { loadTealiumScript, startTealium } from "./config/tealium";
import { loadJQueryScript } from "./utils/load-jquery-script";
import { loadThemeStyles } from "./utils/load-theme-styles";
import { HostModes, HostModesValue, StyleType } from "./types";
import { determineStyleVariant } from "./utils";

type PaymentusHostedIframeAppProps = {
  envData: EnvironmentsDataType;
};

const renderReactDOM = () =>
  ReactDOM.render(
    <PaymentusHostedIframeApp
      envData={{
        paymentsUrl:
          ENV_URL_MAP[window.location.hostname] ||
          "https://dev.apis.frontdoorhome.com/",
      }}
    />,
    document.getElementById("paymentus-iframe-root")
  );

const PaymentusHostedIframeApp: FC<PaymentusHostedIframeAppProps> = ({
  envData,
}) => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App
          appSettings={{
            language: "en_US",
            textTemplatesByLanguage: {
              en_US: enTextTemplates,
            },
          }}
        >
          <Provider store={store}>
            <IframeModeChecker>
              <IframeResizer>
                <PaymentusHostedIframe
                  data-testid={"Paymentus_Hosted_Iframe"}
                  envData={envData}
                />
              </IframeResizer>
            </IframeModeChecker>
          </Provider>
        </App>
      </BrowserRouter>
    </React.StrictMode>
  );
};
const initializeApplication = async () => {
  const searchParams = new URLSearchParams(document.location.search);
  const preload = searchParams.get("preload") === "true" ? "preload" : "";
  const analytics = searchParams.get("analytics") === "true" ? "analytics" : "";
  const theme = (searchParams.get("theme") || "default") as StyleType;
  const iframeMode = (searchParams.get("mode") ||
    HostModesValue.WEB) as HostModes;
  store.dispatch(setStyleType(theme));

  const styleVariant = determineStyleVariant(iframeMode, theme);
  store.dispatch(setStyleVariant(styleVariant));

  document.getElementById("paymentus-iframe-root")?.classList.add(styleVariant);

  switch (preload || analytics) {
    case "preload":
      await loadThemeStyles(theme);
      break;
    case "analytics":
      await Promise.all([
        loadThemeStyles(theme),
        loadJQueryScript(),
        loadTealiumScript(),
      ]);
      renderReactDOM();
      startTealium();
      break;
    default:
      await loadThemeStyles(theme);
      renderReactDOM();
      break;
  }
};

initializeApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
