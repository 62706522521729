import React, { FC } from "react";
import { useIframeMode } from "../utils";
import { Notification, useAppContext } from "@ftdr/blueprint-components-react";
import { useSelector } from "react-redux";
import { PaymentRootState } from "../config/store";
import { NativeNotification } from "./native-notification";

export const IframeModeChecker: FC = ({ children }) => {
  const { storeIframeMode } = useIframeMode();

  const state = useSelector((state: PaymentRootState) => state.paymentState);

  const {
    appSettings: { localizedText },
  } = useAppContext();

  return (
    <div>
      {storeIframeMode
        ? children
        : state?.error?.isError &&
          (state?.isNative ? (
            <NativeNotification
              message={
                state?.error?.message || localizedText(`WRONG_MODE_ERROR`)
              }
              status="error"
              styleVariant={state?.styleVariant}
            />
          ) : (
            <Notification
              variant="inline"
              status="error"
              size="medium"
              showStatusLabel={true}
              inline={true}
            >
              {state?.error?.message || localizedText(`WRONG_MODE_ERROR`)}
            </Notification>
          ))}
    </div>
  );
};
