import {
  Payment3_paymentmethod_coordinatorJSClient,
  payment3_paymentmethod_coordinatorpb,
} from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { payment3_paymentus_securepb } from "@ftdr/payment3_paymentus_secure-js-client";

export type PaymentMethodClient = Payment3_paymentmethod_coordinatorJSClient;
export type RegistrationRequest =
  payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationRequest;
export type MethodSetupRequest =
  payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRequest;
export type RegistrationResponse =
  payment3_paymentmethod_coordinatorpb.ICreatePaymentMethodRegistrationResponse;

export type SecureRequest =
  payment3_paymentus_securepb.IPaymentusPrePaymentMethod;

export interface PaymentusFormInputs {
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmation?: string;
  saveInWallet: boolean;
}

export type HostModes = "native" | "web" | "";
//TODO takethis type form Payment MFE
export type StyleType = "AHSRebrand" | "default";
export type StyleVariant =
  | "mobileFTDR"
  | "mobileAHS"
  | "web"
  | "webAHSRebrand"
  | "";

export const HostModesValue: Record<string, HostModes> = {
  NATIVE: "native",
  WEB: "web",
  EMPTY: "",
};

export interface TealiumMessage {
  tealium_event: "tokenization complete"; // currently the only message we send, could be expanded for more
}

export interface TealiumApiResponseMessage extends TealiumMessage {
  api_response_status: number;
  event_timestamp: number;
}

export type PaymentusFormsInputsKeys = keyof PaymentusFormInputs;

export type StyledElements =
  | "PaymentusContainer"
  | "PaymentusInputsContainer"
  | "PaymentusTextInputContainer"
  | "TextLabel"
  | "FormField"
  | "InputElement";

export type DefaultPaymentusStylesType = Record<
  // HostModes,
  StyleVariant,
  Record<StyledElements, string>
>;
