import { useCallback, useEffect, useRef } from "react";
import "../assets/paymentus-input.css";
import { payment3_paymentmethodpb } from "@ftdr/payment3_paymentmethod_coordinator-js-client";

import {
  MessageType,
  MESSAGE_ACTION,
  MESSAGE_STATUS,
  PaymentusFormStyles,
} from "@ftdr/payment-method-micro-frontend";
import { PaymentusFormRef } from "../form/paymentus-form";
import { usePaymentMethodClientContext } from "../config/contexts/payment-method-client-context";
import {
  setPaymentusFormConfig,
  setTestIdName,
  useAppDispatch,
} from "../config/store";
import { useSendMessageToParent } from "../utils";
import { isAndroid } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import useScript from "react-script-hook";

export const useReactNativeInitialization = () => {
  const { createPaymentMethodRegistration } = usePaymentMethodClientContext();
  const paymentusFormRef = useRef<PaymentusFormRef>(null);
  const dispatch = useAppDispatch();
  const { sendMessageToParent } = useSendMessageToParent();

  const onMessageHandler = useCallback(
    (stringifiedMessage: Event, iframeIdentifier) => {
      try {
        const message = JSON.parse(stringifiedMessage.data) as MessageType;
        if (iframeIdentifier === message?.data?.iframe_data?.iframe_identifier)
          switch (message?.action) {
            case MESSAGE_ACTION.TRIGGER_REGISTRATION:
              createPaymentMethodRegistration({
                paymentMethod: {
                  details: {
                    type: payment3_paymentmethodpb.PaymentMethodType.ACH,
                  },
                  owner:
                    message?.data?.registration_request_data?.paymentMethod
                      ?.owner,
                  source: message?.data?.registration_request_data
                    ?.paymentMethod?.source?.name
                    ? {
                        name: message?.data?.registration_request_data
                          ?.paymentMethod?.source?.name,
                      }
                    : undefined,
                },
              });
              break;
            case MESSAGE_ACTION.SUBMIT:
              paymentusFormRef?.current?.submitForm(message?.data?.submit_data);
              break;
            case MESSAGE_ACTION.PAYMENTUS_FORM_CONFIG:
              dispatch(
                setPaymentusFormConfig(message?.data?.paymentus_form_config)
              );
              dispatch(setTestIdName(message?.data?.test_id_name || ""));
              if (message?.data?.paymentus_form_config?.formStyles) {
                appendStyle(
                  changeCSSObjectToCSSStyle(
                    message?.data?.paymentus_form_config?.formStyles
                  )
                );
              }
              break;
          }
      } catch {}
    },
    [createPaymentMethodRegistration, dispatch]
  );

  useEffect(() => {
    const rootReference = isAndroid ? document : window;
    const iframeIdentifier = uuidv4();
    rootReference.addEventListener("message", (event) =>
      onMessageHandler(event, iframeIdentifier)
    );
    sendMessageToParent({
      status: MESSAGE_STATUS.PAYMENTUS_IFRAME_ON_READY,
      data: {
        iframe_data: {
          iframe_identifier: iframeIdentifier,
        },
      },
    });
    return () => {
      rootReference.removeEventListener("message", (event) =>
        onMessageHandler(event, iframeIdentifier)
      );
    };
  }, [onMessageHandler, sendMessageToParent]);

  return {
    paymentusFormRef,
  };
};

const appendStyle = (style: string) => {
  if (!document.querySelector(`style[id~="MFE_style"]`)) {
    const css = document.createElement("style");
    css.id = "MFE_style";
    css.innerText = style;
    document.head.appendChild(css);
  }
};
const changeCSSObjectToCSSStyle = (
  styleObject?: PaymentusFormStyles
): string => {
  if (styleObject) {
    const styleObjectKeys = Object.keys(styleObject) as Array<
      keyof PaymentusFormStyles
    >;
    return styleObjectKeys
      .map((className) => {
        const cssProperties = styleObject?.[className];
        const cssPropertiesString =
          cssProperties &&
          Object.entries(cssProperties)
            .map(
              ([k, v]) =>
                `${k.replace(
                  /[A-Z]/g,
                  (match) => `-${match.toLowerCase()}`
                )}:${v};`
            )
            .join(" ");
        return `.${className} {${cssPropertiesString}}`;
      })
      .join(" ");
  }
  return "";
};
export const useReactWebInitialization = () => {
  const { createPaymentMethodRegistration } = usePaymentMethodClientContext();
  const paymentusFormRef = useRef<PaymentusFormRef>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.iFrameResizer = {
      onMessage: (message: MessageType) => {
        switch (message.action) {
          case MESSAGE_ACTION.TRIGGER_REGISTRATION:
            createPaymentMethodRegistration({
              paymentMethod: {
                details: {
                  type: payment3_paymentmethodpb.PaymentMethodType.ACH,
                },
                owner:
                  message?.data?.registration_request_data?.paymentMethod
                    ?.owner,
                source: message?.data?.registration_request_data?.paymentMethod
                  ?.source?.name
                  ? {
                      name: message?.data?.registration_request_data
                        ?.paymentMethod?.source?.name,
                    }
                  : undefined,
              },
            });
            break;
          case MESSAGE_ACTION.SUBMIT:
            paymentusFormRef?.current?.submitForm(message.data?.submit_data);
            break;
          case MESSAGE_ACTION.PAYMENTUS_FORM_CONFIG:
            dispatch(
              setPaymentusFormConfig(message?.data?.paymentus_form_config)
            );
            if (message?.data?.paymentus_form_config?.formStyles) {
              appendStyle(
                changeCSSObjectToCSSStyle(
                  message?.data?.paymentus_form_config?.formStyles
                )
              );
            }
            break;
        }
      },
    };
  }, [createPaymentMethodRegistration, dispatch]);

  const [loading] = useScript({
    src: "/iframe-resizer-script/iframeResizer.contentWindow.min.js",
    checkForExisting: true,
  });

  return {
    paymentusFormRef,
    loading,
  };
};
