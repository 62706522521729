import { useCallback } from "react";
import { usePaymentMethodClientContext } from "../../config/contexts/payment-method-client-context";
import {
  payment3_commonpb,
  payment3_paymentmethodpb,
} from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import { MessageType } from "@ftdr/payment-method-micro-frontend";
import { useSendMessageToParent } from "../../utils";

export const useCreatePaymentusPaymentMethod = () => {
  const { createPaymentMethod } = usePaymentMethodClientContext();
  const { sendMessageToParent } = useSendMessageToParent();

  const createPaymentusPaymentMethod = useCallback(
    async (
      requestData,
      registrationID?: string | null,
      authSSOToken?: string | null,
      encodedRegistrationPayload?: string | null
    ): Promise<MessageType | undefined> => {
      const createPaymentMethodResult = await createPaymentMethod({
        registrationID,
        paymentMethod: {
          details: {
            type: payment3_paymentmethodpb.PaymentMethodType.ACH,
          },
          owner: requestData?.paymentus?.owner,
          includedInWallet: requestData?.paymentus?.includedInWallet,
          source: requestData?.paymentus?.source,
          processor: {
            processor: payment3_commonpb.PaymentProcessor.Paymentus,
            paymentus: {
              securePayload: encodedRegistrationPayload,
            },
          },
        },
      });
      sendMessageToParent(createPaymentMethodResult);
      return createPaymentMethodResult;
    },
    [createPaymentMethod, sendMessageToParent]
  );
  return createPaymentusPaymentMethod;
};
