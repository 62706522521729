import { HostModes, StyleType, StyleVariant } from "../types";

export const determineStyleVariant = (
  iframeMode: HostModes,
  theme: StyleType
): StyleVariant => {
  let styleVariant;

  switch (true) {
    case iframeMode === "web" && theme === "default":
      styleVariant = "web";
      break;
    case iframeMode === "web" && theme === "AHSRebrand":
      styleVariant = "webAHSRebrand";
      break;
    case iframeMode === "native" && theme === "default":
      styleVariant = "mobileFTDR";
      break;
    case iframeMode === "native" && theme === "AHSRebrand":
      styleVariant = "mobileAHS";
      break;
    default:
      styleVariant = "web";
  }

  return styleVariant as StyleVariant;
};
