import { StyleType } from "../types";

export const loadThemeStyles = async (
  theme: StyleType
): Promise<Event | void> => {
  try {
    if (theme === "AHSRebrand") {
      await import(
        // @ts-ignore
        "@ftdr/blueprint-core/themes/ahs-2024.css"
      );
    } else {
      await import(
        // @ts-ignore
        "@ftdr/blueprint-core/themes/ahs-web-app.css"
      );
    }
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};
