import { useCallback } from "react";
import { usePaymentMethodClientContext } from "../../config/contexts/payment-method-client-context";
import { PaymentusFormInputs, PaymentusFormsInputsKeys } from "../../types";
import {
  MESSAGE_STATUS,
  ErrorType,
  MessageType,
} from "@ftdr/payment-method-micro-frontend";
import { useAppDispatch, setError } from "../../config/store";
import { useAppContext } from "@ftdr/blueprint-components-react";
import { ResponseErrorType, useSendMessageToParent } from "../../utils";

export const PAYMETUS_INPUTS_FORM_MAP: Record<
  PaymentusFormsInputsKeys,
  string[]
> = {
  bankRoutingNumber: ["Routing Number:", "routing number"],
  bankAccountNumber: ["Account Number:", "bank account number"],
  bankAccountNumberConfirmation: ["bankAccountNumberConfirmation"],
  saveInWallet: ["saveInWallet"],
};

export const PAYMENTUS_INPUTS_ARRAY: Array<PaymentusFormsInputsKeys> = [
  "bankRoutingNumber",
  "bankAccountNumber",
  "bankAccountNumberConfirmation",
];

export type DecodedFormErrorsType = Partial<
  Record<PaymentusFormsInputsKeys, { message?: string; type?: string }>
>;

export type TokenizeAchDataReturnType = {
  originalData?: MessageType;
  decodedFormErrors: DecodedFormErrorsType;
} & Pick<MessageType, "status">;

const BACKEND_ERROR_PREFIX = "invalid request body or parameter: ";

export const useTokenizeAchData = () => {
  const { createPaymentusToken } = usePaymentMethodClientContext();
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const dispatch = useAppDispatch();

  const { sendMessageToParent } = useSendMessageToParent();

  const parseFormErrors = useCallback(
    (paymentusSecureResultError?: ErrorType) => {
      const parsedArrayErrors = paymentusSecureResultError
        ?.decodedError!.map(({ message }) =>
          (message || "")
            .replace(BACKEND_ERROR_PREFIX, "")
            .replace("routingNumber", "Routing Number")
            .replace("accountNumber", "Account Number")
        )
        .filter(Boolean);
      const decodedFormErrors =
        PAYMENTUS_INPUTS_ARRAY.reduce<DecodedFormErrorsType>(
          (acc, inputName) => {
            const inputErrorMessage = parsedArrayErrors?.find((errorMessage) =>
              PAYMETUS_INPUTS_FORM_MAP[inputName].some((inputNamePart) =>
                errorMessage.includes(inputNamePart)
              )
            );
            return {
              ...acc,
              ...(inputErrorMessage && {
                [inputName]: {
                  message: inputErrorMessage,
                  type: "custom",
                },
              }),
            };
          },
          {}
        );

      const [defaultErrorMessage] = parsedArrayErrors || [
        localizedText("UNABLE_PAYMENT_METHOD_PROCESS_ERROR"),
      ];
      return Object.keys(decodedFormErrors).length
        ? decodedFormErrors
        : {
            bankRoutingNumber: {
              type: "custom",
              message: defaultErrorMessage,
            },
            bankAccountNumber: {
              type: "custom",
              message: defaultErrorMessage,
            },
          };
    },
    [localizedText]
  );

  const tokenizeAchData = useCallback<
    (
      formData: PaymentusFormInputs,
      authSSOToken?: string | null
    ) => Promise<TokenizeAchDataReturnType | undefined>
  >(
    async (formData, authSSOToken) => {
      const { bankRoutingNumber, bankAccountNumber } = formData;
      let paymentusSecureResult = await createPaymentusToken({
        authSSOToken,
        routingNumber: bankRoutingNumber,
        accountNumber: bankAccountNumber,
      });
      let errorMessage = "";
      let decodedFormErrors: DecodedFormErrorsType = {};
      if (
        paymentusSecureResult?.status ===
        MESSAGE_STATUS.PAYMENT_SECURE_SERVICE_ERROR
      ) {
        const paymentusSecureResultError =
          paymentusSecureResult?.error?.payment_secure_service_error;
        const errorCode = paymentusSecureResultError?.code;
        switch (errorCode) {
          case 400:
            decodedFormErrors = parseFormErrors(paymentusSecureResultError);
            errorMessage =
              decodedFormErrors?.bankRoutingNumber?.message ||
              decodedFormErrors?.bankAccountNumber?.message ||
              localizedText("UNABLE_PAYMENT_METHOD_PROCESS_ERROR");
            break;
          default:
            if (errorCode) {
              errorMessage = localizedText(`ERROR_STATUS_CODE_${errorCode}`);
            } else {
              errorMessage =
                (
                  paymentusSecureResult?.error?.payment_secure_service_error
                    ?.rawError as ResponseErrorType
                )?.lastError?.message ||
                localizedText(`ERROR_STATUS_CODE_UNDEFINED`);
            }
            dispatch(
              setError({
                isError: true,
                message: errorMessage,
              })
            );
        }
        paymentusSecureResult = {
          ...paymentusSecureResult,
          error: {
            ...paymentusSecureResult?.error,
            payment_secure_service_error: {
              ...paymentusSecureResult?.error?.payment_secure_service_error,
              message: errorMessage,
            },
          },
        };
      }
      sendMessageToParent(paymentusSecureResult);
      return {
        status: paymentusSecureResult?.status,
        originalData: paymentusSecureResult,
        decodedFormErrors,
      };
    },
    [
      createPaymentusToken,
      dispatch,
      localizedText,
      sendMessageToParent,
      parseFormErrors,
    ]
  );

  return { tokenizeAchData };
};
