import { Ref } from "react";
import { useSendMessageToParent } from "../utils";
import { useDebouncedCallback } from "use-debounce";
import { MESSAGE_STATUS } from "@ftdr/payment-method-micro-frontend";
import useResizeObserver from "use-resize-observer";

export type UseResizeIframeReturn = Ref<HTMLDivElement> | null;

export const useNativeResizeIframe = (): UseResizeIframeReturn => {
  const { sendMessageToParent } = useSendMessageToParent();
  const debouncedHeightAndSendMessage = useDebouncedCallback((height) => {
    sendMessageToParent({
      status: MESSAGE_STATUS.PAYMENTUS_IFRAME_HEIGHT,
      data: {
        iframe_data: {
          iframe_height: height,
        },
      },
    });
  }, 20);

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      debouncedHeightAndSendMessage(height);
    },
  });

  return ref;
};

export const useWebResizeIframe = (): UseResizeIframeReturn => {
  return null;
};
