import { DefaultPaymentusStylesType } from "./types";

export const CREATE_PAYMENT_METHOD_API_ERRORS = {
  SETUP_INTENT_NOT_FOUND: "PMC_BLE_0011",
};
export const ENV_URL_MAP: Record<string, string> = {
  "paymentus-iframe.test.frontdoorhome.com":
    "https://test.apis.frontdoorhome.com/",
  "paymentus-iframe.test.ftdrinternal.com":
    "https://test.apis.frontdoorhome.com/",
  "paymentus-iframe.dev.ftdrinternal.com":
    "https://dev.apis.frontdoorhome.com/",
  "paymentus-iframe.frontdoorhome.com": "https://prod.apis.frontdoorhome.com/",
  "paymentus-iframe.staging.frontdoorhome.com":
    "https://staging.apis.frontdoorhome.com/",
  localhost: "https://test.apis.frontdoorhome.com/",
  "10.0.2.2": "https://test.apis.frontdoorhome.com/",
};

const makeTealiumBaseUrl = (envTag: "dev" | "qa" | "prod" = "dev") =>
  `https://tags.tiqcdn.com/utag/americanhomeshield/paymentus-iframe/${envTag}/utag.js`;

export const TEALIUM_URL_MAP: Record<string, string> = {
  "paymentus-iframe.test.frontdoorhome.com": makeTealiumBaseUrl("qa"),
  "paymentus-iframe.test.ftdrinternal.com": makeTealiumBaseUrl("qa"),
  "paymentus-iframe.dev.ftdrinternal.com": makeTealiumBaseUrl("dev"),
  "paymentus-iframe.frontdoorhome.com": makeTealiumBaseUrl("prod"),
  "paymentus-iframe.staging.frontdoorhome.com": makeTealiumBaseUrl("qa"),
  localhost: "", //purposefully empty string
};

export const DefaultPaymentusStyles: DefaultPaymentusStylesType = {
  web: {
    PaymentusContainer: "flex justify-center flex-col",
    PaymentusInputsContainer: "grid grid-cols-6 pb-4 last:pb-0",
    PaymentusTextInputContainer: "flex",
    TextLabel: "",
    FormField: "mb-2",
    InputElement: "bg-white",
  },
  webAHSRebrand: {
    PaymentusContainer: "flex justify-center flex-col",
    PaymentusInputsContainer: "grid grid-cols-6 pb-4 last:pb-0",
    PaymentusTextInputContainer: "flex",
    TextLabel: "",
    FormField: "mb-2",
    InputElement: "bg-white",
  },
  mobileFTDR: {
    PaymentusContainer: "flex justify-center flex-col",
    PaymentusInputsContainer: "grid grid-cols-6 pb-4 last:pb-0",
    PaymentusTextInputContainer:
      "flex bg-white rounded-2xl px-6 py-4 shadow items-center",
    TextLabel: "text-gray-400",
    FormField: "",
    InputElement: "bg-white native-form-input border-none",
  },
  mobileAHS: {
    PaymentusContainer: "flex justify-center flex-col",
    PaymentusInputsContainer: "grid grid-cols-6 pb-4 last:pb-0",
    PaymentusTextInputContainer: "flex",
    TextLabel: "mb-1",
    FormField: "mb-2",
    InputElement: "bg-white",
  },
  "": {
    PaymentusContainer: "",
    PaymentusInputsContainer: "",
    PaymentusTextInputContainer: "",
    TextLabel: "",
    FormField: "",
    InputElement: "",
  },
};
