import {
  PaymentusSetupItemType,
  PaymentusSetupItemTypeName,
} from "@ftdr/payment-method-micro-frontend";
import React, { ReactElement, useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PaymentusFormInputs } from "../../types";

import { CheckboxInput, TextInput } from "./inputs";
import { useSelector } from "react-redux";
import { PaymentRootState } from "../../config/store";

export type GenericInputProp = {
  name: PaymentusSetupItemTypeName;
  minLength?: number;
  maxLength?: number;
  errorTypes: Record<string, string>;
  onlyDigits?: boolean;
  matchValue?: keyof PaymentusFormInputs;
} & Partial<PaymentusSetupItemType>;

export const nameMap: Record<PaymentusSetupItemTypeName, string> = {
  bankRoutingNumber: "ROUTING_NUMBER_LABEL",
  bankAccountNumber: "ACCOUNT_NUMBER_LABEL",
  bankAccountNumberConfirmation: "ACCOUNT_NUMBER_CONFIRMATION_LABEL",
  saveInWallet: "SAVE_IN_WALLET_LABEL",
};

export const GenericInput = ({
  name,
  errorTypes,
  minLength,
  maxLength,
  onlyDigits,
  matchValue,
  className,
  label,
  placeholder,
}: GenericInputProp): ReactElement => {
  const { getValues } = useFormContext<PaymentusFormInputs>();

  const isSaveWalletInput = useMemo(() => name === "saveInWallet", [name]);

  const validateFunc = useCallback(
    (value) => {
      if (onlyDigits && isNaN(value)) {
        return errorTypes["onlyDigits"];
      }
      if (matchValue && value !== getValues(matchValue)) {
        return errorTypes["confirm"];
      }
    },
    [errorTypes, matchValue, onlyDigits, getValues]
  );

  const { isNative, testIdName, styleVariant } = useSelector(
    (state: PaymentRootState) => state.paymentState
  );

  return (
    <div className={`w-full ${className}`}>
      <Controller
        name={name}
        rules={{
          ...(errorTypes["required"] && { required: errorTypes["required"] }),
          ...(minLength && {
            minLength: {
              value: minLength || 0,
              message: errorTypes["minLength"],
            },
          }),
          ...(maxLength && {
            maxLength: {
              value: maxLength || 0,
              message: errorTypes["maxLength"],
            },
          }),
          validate: validateFunc,
        }}
        render={({ field }) => (
          <>
            {isSaveWalletInput && !isNative ? (
              <CheckboxInput label={label} {...field} />
            ) : (
              <TextInput
                placeholder={placeholder}
                label={label}
                isNative={isNative}
                testIdName={testIdName}
                styleVariant={styleVariant}
                {...field}
              />
            )}
          </>
        )}
      />
    </div>
  );
};
