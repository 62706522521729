export const enTextTemplates = {
  ROUTING_NUMBER_LABEL: "Routing Number",
  ACCOUNT_NUMBER_LABEL: "Account Number",
  ACCOUNT_NUMBER_CONFIRMATION_LABEL: "Confirm Account Number",
  SAVE_IN_WALLET_LABEL: "Save In Wallet",

  SEND_MESSAGE_ERROR: "Communication with the host was interrupted",
  NOT_ALLOWED_VIEW_ERROR: "You are not allowed to view iFrame Content",
  TOKEN_SEMANTIC_INVALID_ERROR: "Token is not semantic valid",

  ROUTING_NUMBER_ERROR: "Routing number in not valid",
  REQUIRED_FILED_ERROR: "This field is required",
  ROUTING_NUMBER_LENGTH_ERROR: "Routing number should have 9 characters",
  ROUTING_NUMBER_DIGITS_ERROR: "Routing number should contain digits only",

  ACCOUNT_NUMBER_ERROR: "Account number in not valid",
  ACCOUNT_NUMBER_LENGTH_ERROR: "Account number should have 4-17 characters",
  ACCOUNT_NUMBER_DIGITS_ERROR: "Account number should contain digits only",
  ACCOUNT_NUMBER_CONFIRMATION_DIGITS_ERROR:
    "Account number confirmation should contain digits only",
  ACCOUNT_NUMBER_CONFIRMATION_ERROR: "Account Numbers do not match",
  ERROR_STATUS_CODE_400: "Request failed with status code 400",
  ERROR_STATUS_CODE_401: "Not authorized: Request failed with status code 401",
  ERROR_STATUS_CODE_403:
    "Missing permissions: Request failed with status code 403",
  ERROR_STATUS_CODE_404: "Not found: Request failed with status code 404",
  ERROR_STATUS_CODE_409: "Request failed with status code 409",
  ERROR_STATUS_CODE_500: "Internal server error",
  ERROR_STATUS_CODE_503: "Request failed with status code 503",
  ERROR_STATUS_CODE_UNDEFINED: "Server error occurred",

  UNABLE_PAYMENT_METHOD_PROCESS_ERROR:
    "We are unable to process this payment method",

  WRONG_MODE_ERROR: "Iframe mode is not correct.",
};
