import React, { FC } from "react";
import { Notification, useAppContext } from "@ftdr/blueprint-components-react";
import "./assets/paymentus-input.css";
import { PaymentMethodClientContext } from "./config/contexts/payment-method-client-context";
import { EnvironmentsDataType } from "./config/environment";
import { PaymentusHostedIframeInternal } from "./paymentus-hosted-iframe-internal";
import { useTokenSemanticValidation } from "./utils";
import { NativeNotification } from "./components";
import { useSelector } from "react-redux";
import { PaymentRootState } from "./config/store";

type PaymentusHostedIframeProps = {
  envData: EnvironmentsDataType;
};

export const PaymentusHostedIframe: FC<PaymentusHostedIframeProps> = ({
  envData,
}) => {
  const isTokenSemanticValid = useTokenSemanticValidation();
  const {
    appSettings: { localizedText },
  } = useAppContext();

  const { isNative, styleVariant } = useSelector(
    (state: PaymentRootState) => state.paymentState
  );

  return (
    <>
      {isTokenSemanticValid ? (
        <PaymentMethodClientContext paymentsUrl={envData.paymentsUrl}>
          <PaymentusHostedIframeInternal
            data-testid={"Paymentus_Hosted_Iframe_Internal"}
          />
        </PaymentMethodClientContext>
      ) : isNative ? (
        <NativeNotification
          message={localizedText(`TOKEN_SEMANTIC_INVALID_ERROR`)}
          status="error"
          styleVariant={styleVariant}
        />
      ) : (
        <Notification
          variant="inline"
          status="error"
          size="medium"
          showStatusLabel={true}
          inline={true}
        >
          {localizedText(`TOKEN_SEMANTIC_INVALID_ERROR`)}
        </Notification>
      )}
    </>
  );
};
