import { useSelector } from "react-redux";
import { PaymentRootState } from "./config/store";
import {
  Notification,
  ProgressIndicator,
  useAppContext,
} from "@ftdr/blueprint-components-react";
import React from "react";
import PaymentusForm from "./form/paymentus-form";
import { usePaymentusHostedIframe } from "./hooks/use-paymentus-hosted-iframe";
import { NativeNotification } from "./components";
import classNames from "classnames";
import { DefaultPaymentusStyles } from "./const";

export const PaymentusHostedIframeInternal = () => {
  const { paymentusFormRef, loading } = usePaymentusHostedIframe();
  const state = useSelector((state: PaymentRootState) => state.paymentState);
  const {
    appSettings: { localizedText },
  } = useAppContext();

  return (
    <div
      className={classNames(
        DefaultPaymentusStyles[state?.styleVariant].PaymentusContainer,
        "paymentusContainer"
      )}
    >
      {loading || !state.isRegistrationReady ? (
        <ProgressIndicator
          className="my-4"
          size={state?.isNative ? "small" : "medium"}
          color={state?.styleType === "AHSRebrand" ? "interactive" : "primary"}
        />
      ) : (
        <div>
          {state?.error?.isError ? (
            state?.isNative ? (
              <NativeNotification
                message={
                  state?.error?.message ||
                  localizedText(`NOT_ALLOWED_VIEW_ERROR`)
                }
                status="error"
                styleVariant={state?.styleVariant}
              />
            ) : (
              <Notification
                variant="inline"
                status="error"
                size="medium"
                showStatusLabel={true}
                inline={true}
              >
                {state?.error?.message ||
                  localizedText(`NOT_ALLOWED_VIEW_ERROR`)}
              </Notification>
            )
          ) : (
            <div>
              <PaymentusForm
                data-testid={"Paymentus_Form"}
                ref={paymentusFormRef}
              />
              {state?.isLoading && (
                <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center z-10">
                  <ProgressIndicator
                    size="medium"
                    color={
                      state?.styleType === "AHSRebrand"
                        ? "interactive"
                        : "primary"
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
