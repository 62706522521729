import "../assets/paymentus-input.css";
import * as useResizeIframeHooks from "./use-resize-iframe-hooks";
import { Ref } from "react";
import { useSelector } from "react-redux";
import { PaymentRootState } from "../config/store";

const RESIZER_MODES_MAP: Record<any, keyof typeof useResizeIframeHooks> = {
  native: "useNativeResizeIframe",
  web: "useWebResizeIframe",
};

export type UseResizeIframeReturn = Ref<HTMLDivElement> | undefined;

export const useResizeIframe = (): UseResizeIframeReturn => {
  const storeIframeMode = useSelector(
    (state: PaymentRootState) => state.paymentState.iframeMode
  );

  return useResizeIframeHooks[RESIZER_MODES_MAP[storeIframeMode]]();
};
