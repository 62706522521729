import { useCallback } from "react";
import { MESSAGE_STATUS } from "@ftdr/payment-method-micro-frontend";
import { RegistrationRequest } from "../../../../types";
import {
  useAppDispatch,
  setRegistrationResponse,
  setError,
  setDebugIdentifiers,
  UuidType,
} from "../../../store";
import { useAppContext } from "@ftdr/blueprint-components-react";
import { decodeCommonError, useSendMessageToParent } from "../../../../utils";
import { v4 as uuidv4 } from "uuid";
import {
  PaymentMethodCoordinatorJSClient,
  PaymentusSecureJSClient,
} from "../clients";

export const useCreatePaymentMethodRegistration = (
  paymentMethodCoordinatorClient: PaymentMethodCoordinatorJSClient | undefined,
  paymentusSecureClient: PaymentusSecureJSClient | undefined
) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const dispatch = useAppDispatch();
  const { sendMessageToParent } = useSendMessageToParent();

  const setupdDebugIdentifiers = useCallback((): UuidType => {
    const debugIdentifiers = {
      traceId: uuidv4(),
      spanId: uuidv4(),
    };
    paymentMethodCoordinatorClient?.setDebugIdentifiers(debugIdentifiers);
    paymentusSecureClient?.setDebugIdentifiers(debugIdentifiers);

    dispatch(setDebugIdentifiers(debugIdentifiers));
    return debugIdentifiers;
  }, [dispatch, paymentusSecureClient, paymentMethodCoordinatorClient]);

  const createPaymentMethodRegistration = useCallback(
    async (requestData: RegistrationRequest) => {
      let result = undefined;
      const newDebugIdentifiers = setupdDebugIdentifiers();
      try {
        result =
          await paymentMethodCoordinatorClient?.createPaymentMethodRegistration(
            requestData
          );
        dispatch(
          setRegistrationResponse({
            registrationResponse: {
              registration: {
                ...result?.data.registration,
                paymentus: {
                  ...result?.data.registration?.paymentus,
                },
              },
            },
            isRegistrationReady: true,
          })
        );
        // Since we are not handling the isLoading state for the registration call internally
        // We need to send a message to the parent of the iframe that the iframe is loaded and registration is successful
        sendMessageToParent({
          status: MESSAGE_STATUS.PAYMENTUS_REGISTRATION_SUCCESS,
          data: {
            registration_data: result?.data,
          },
        });
        return result?.data;
      } catch (ex: any) {
        const decodedError = decodeCommonError(ex);
        const errorStatusCode = ex?.lastError?.response?.status;
        const errorObject = {
          isError: true,
          message: localizedText(`ERROR_STATUS_CODE_${errorStatusCode}`),
        };
        dispatch(setError(errorObject));

        sendMessageToParent({
          status: MESSAGE_STATUS.PAYMENTUS_REGISTRATION_ERROR,
          error: {
            registration_error: {
              message: errorObject.message,
              decodedError: decodedError,
              rawError: ex,
              uuid: newDebugIdentifiers,
            },
          },
        });
      }
      return result?.data;
    },
    [
      paymentMethodCoordinatorClient,
      dispatch,
      localizedText,
      sendMessageToParent,
      setupdDebugIdentifiers,
    ]
  );

  return createPaymentMethodRegistration;
};
