import React from "react";
import { Checkbox, useAppContext } from "@ftdr/blueprint-components-react";
import { GenericInputProp, nameMap } from "../generic-input";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { FieldValues } from "react-hook-form";
import { PaymentusSetupItemTypeName } from "@ftdr/payment-method-micro-frontend";
import { PaymentState } from "../../../config/store";

export type CheckboxInputProp = Partial<GenericInputProp> &
  ControllerRenderProps<FieldValues, PaymentusSetupItemTypeName> &
  Partial<PaymentState>;

export const CheckboxInput = ({
  name,
  label,
  value,
  onChange,
}: CheckboxInputProp) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();

  return (
    <Checkbox
      label={label || localizedText(nameMap[name])}
      name={name}
      checked={!!value}
      data-testid={`Payment__Type__Stripe__saveInWallet`}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
};
