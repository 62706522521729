import { useCallback, useEffect, useMemo, useRef } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { payment3_paymentmethodpb } from "@ftdr/payment3_paymentmethod_coordinator-js-client";
import {
  MessageType,
  MESSAGE_STATUS,
  PaymentusDefaultSetup,
} from "@ftdr/payment-method-micro-frontend";

import {
  PaymentRootState,
  setError as setStoreError,
  setIsLoading,
  useAppDispatch,
} from "../config/store";
import { usePaymentMethodClientContext } from "../config/contexts/payment-method-client-context";
import { useSendMessageToParent } from "../utils";
import { PaymentusFormInputs } from "../types";
import { useCreatePaymentusPaymentMethod, useTokenizeAchData } from "./hooks";

import { PaymentusFormsInputsKeys } from "../types";
import { generateSetupArray } from "./utils";

type ErrorType = "redis_cache_404_error";

export const ERRORS_TYPE: Record<string, ErrorType> = {
  REDIS_CACHE_404_ERROR: "redis_cache_404_error",
};

export const usePaymentusForm = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: PaymentRootState) => state.paymentState);
  const elementsConfig = useMemo(() => {
    try {
      return generateSetupArray(state?.paymentusFormConfig?.fieldsSetup);
    } catch (e) {
      console.error(
        "Wrong Config provided, using default one for Paymentus Elements."
      );
      return generateSetupArray(PaymentusDefaultSetup);
    }
  }, [state.paymentusFormConfig]);
  const methods = useForm<PaymentusFormInputs>({
    mode: state?.paymentusFormConfig?.mode || "onSubmit",
    reValidateMode: state?.paymentusFormConfig?.reValidateMode || "onBlur",
    defaultValues: {
      bankRoutingNumber: "",
      bankAccountNumber: "",
      bankAccountNumberConfirmation: "",
      saveInWallet: false,
    },
  });
  const { handleSubmit, reset, setError, formState, watch } = methods;
  const { sendMessageToParent } = useSendMessageToParent();
  const { tokenizeAchData } = useTokenizeAchData();
  const createPaymentusPaymentMethod = useCreatePaymentusPaymentMethod();
  const { createPaymentMethodRegistration } = usePaymentMethodClientContext();

  const prevAreAllFieldsFilledRef = useRef<boolean | null>(null);

  useEffect(() => {
    const subscription = watch((value) => {
      const areAllFieldsFilled = Object.keys(value)
        .filter((key) => key !== "saveInWallet")
        .filter(
          (key) =>
            state?.paymentusFormConfig?.fieldsSetup.hasOwnProperty(
              "bankAccountNumberConfirmation"
            ) || key !== "bankAccountNumberConfirmation"
        )
        .every((key) => value[key as keyof PaymentusFormInputs] !== "");

      if (areAllFieldsFilled !== prevAreAllFieldsFilledRef.current) {
        prevAreAllFieldsFilledRef.current = areAllFieldsFilled;
        sendMessageToParent({
          status: MESSAGE_STATUS.PAYMENTUS_FORM_STATUS,
          data: {
            paymentus_form_status: {
              isCompleted: areAllFieldsFilled,
            },
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [sendMessageToParent, state?.paymentusFormConfig?.fieldsSetup, watch]);

  const handleFormValidation = useCallback<
    SubmitErrorHandler<PaymentusFormInputs>
  >(
    (form_error) => {
      sendMessageToParent({
        status: MESSAGE_STATUS.PAYMENTUS_FORM_INVALID_FIELDS,
        error: {
          paymentus_form_error: form_error,
        },
      });
    },
    [sendMessageToParent]
  );

  const handleCreatePaymentMethod = useCallback(
    async (
      requestData,
      registrationID,
      authSSOToken,
      encodedRegistrationPayload
    ): Promise<MessageType | undefined> => {
      const createPaymentusPaymentMethodResult =
        await createPaymentusPaymentMethod(
          requestData,
          registrationID,
          authSSOToken,
          encodedRegistrationPayload
        );

      switch (createPaymentusPaymentMethodResult?.status) {
        case MESSAGE_STATUS.CREATE_PAYMENT_METHOD_SUCCESS:
          reset({
            bankRoutingNumber: "",
            bankAccountNumber: "",
            bankAccountNumberConfirmation: "",
          });
          createPaymentMethodRegistration({
            paymentMethod: {
              details: {
                type: payment3_paymentmethodpb.PaymentMethodType.ACH,
              },
            },
          });
          break;
        case MESSAGE_STATUS.CREATE_PAYMENT_METHOD_ERROR:
          dispatch(
            setStoreError({
              isError: true,
              message:
                createPaymentusPaymentMethodResult?.error
                  ?.create_payment_method_error?.message,
            })
          );
          break;
      }
      return createPaymentusPaymentMethodResult;
    },
    [
      createPaymentusPaymentMethod,
      createPaymentMethodRegistration,
      reset,
      dispatch,
    ]
  );

  const handleCreatePaymentusPaymentMethod = useCallback(
    async (
      requestData,
      authSSOToken?: string | null,
      registrationID?: string | null
    ): Promise<MessageType | undefined> => {
      let createPaymentusPaymentMethodResult: MessageType | undefined =
        undefined;
      await handleSubmit(
        async (formData) => {
          dispatch(setIsLoading(true));
          const paymentusSecureResult = await tokenizeAchData(
            formData,
            authSSOToken
          );
          if (
            paymentusSecureResult?.status ===
            MESSAGE_STATUS.PAYMENT_SECURE_SERVICE_SUCCESS
          ) {
            createPaymentusPaymentMethodResult =
              await handleCreatePaymentMethod(
                {
                  paymentus: {
                    ...requestData.paymentus,
                    // To check
                    includedInWallet:
                      requestData?.paymentus?.includedInWallet ||
                      formData?.saveInWallet,
                  },
                },
                registrationID,
                paymentusSecureResult?.originalData?.data
                  ?.payment_secure_service_data?.authSSOToken,
                paymentusSecureResult?.originalData?.data
                  ?.payment_secure_service_data?.encodedRegistrationPayload
              );
          } else {
            Object.entries(
              paymentusSecureResult?.decodedFormErrors || {}
            ).forEach(([name, { message, type }]) => {
              setError(name as PaymentusFormsInputsKeys, {
                type,
                message,
              });
            });
            createPaymentusPaymentMethodResult = paymentusSecureResult;
          }
          dispatch(setIsLoading(false));
        },
        (error) => handleFormValidation(error)
      )();
      return createPaymentusPaymentMethodResult;
    },
    [
      handleSubmit,
      handleFormValidation,
      tokenizeAchData,
      dispatch,
      setError,
      handleCreatePaymentMethod,
    ]
  );

  return {
    handleFormValidation,
    handleCreatePaymentusPaymentMethod,
    state,
    handleSubmit,
    reset,
    methods,
    setError,
    formState,
    elementsConfig,
  };
};
