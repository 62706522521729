import { common } from "@ftdr/payment3_paymentmethod_coordinator-js-client";

export type ResponseErrorType = {
  lastError: {
    response: {
      data: Uint8Array;
    };
    message: string;
  };
  message: string;
};

export type DecodedErrorType = common.IError[];

export const decodeCommonError = (
  error: ResponseErrorType
): DecodedErrorType => {
  if (error.lastError?.response) {
    try {
      const errorResponse = common.ErrorResponse.decode(
        new Uint8Array(error.lastError?.response?.data)
      );
      return errorResponse.errors;
    } catch (ex) {
      return [{ message: error.message || error.lastError?.message, code: "" }];
    }
  }
  return [];
};
