import {
  FormsSetupType,
  FormsSetupDefaultValue,
} from "@ftdr/payment-method-micro-frontend";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  HostModes,
  HostModesValue,
  RegistrationResponse,
  StyleType,
  StyleVariant,
} from "../../../types";

export type UuidType = {
  traceId: string;
  spanId: string;
};

export type PaymentState = {
  isLoading?: boolean;
  registrationResponse?: RegistrationResponse;
  isRegistrationReady?: boolean;
  error?: {
    isError?: boolean;
    message?: string;
  };
  debugIdentifiers?: UuidType;
  paymentusFormConfig?: FormsSetupType["paymentusForm"];
  iframeMode: HostModes;
  isNative?: boolean;
  iframeIdentifier?: string;
  testIdName?: string;
  styleType?: StyleType;
  styleVariant: StyleVariant;
};

export const initialState: PaymentState = {
  isLoading: false,
  isRegistrationReady: false,
  error: {
    isError: false,
    message: "",
  },
  debugIdentifiers: {
    traceId: "",
    spanId: "",
  },
  paymentusFormConfig: FormsSetupDefaultValue.paymentusForm,
  iframeMode: HostModesValue.EMPTY,
  styleVariant: "",
};

export const paymentSlice = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setRegistrationResponse(
      state,
      {
        payload,
      }: PayloadAction<{
        registrationResponse: RegistrationResponse | undefined;
        isRegistrationReady?: boolean;
      }>
    ) {
      state.registrationResponse = payload.registrationResponse || undefined;
      state.isRegistrationReady = payload.isRegistrationReady || undefined;
    },
    setError(
      state,
      {
        payload,
      }: PayloadAction<{
        isError?: boolean;
        message?: string;
      }>
    ) {
      state.error = payload;
      state.isRegistrationReady = true;
    },
    setDebugIdentifiers(state, { payload }: PayloadAction<UuidType>) {
      state.debugIdentifiers = payload;
    },
    setPaymentusFormConfig(
      state,
      { payload }: PayloadAction<FormsSetupType["paymentusForm"] | undefined>
    ) {
      state.paymentusFormConfig = payload;
    },
    setIframeMode(state, { payload }: PayloadAction<HostModes>) {
      state.iframeMode = payload;
      state.isNative = payload === HostModesValue.NATIVE;
    },
    setIframeIdentifier(state, { payload }: PayloadAction<string>) {
      state.iframeIdentifier = payload;
    },
    setTestIdName(state, { payload }: PayloadAction<string>) {
      state.testIdName = payload;
    },
    setStyleType(state, { payload }: PayloadAction<StyleType>) {
      state.styleType = payload;
    },
    setStyleVariant(state, { payload }: PayloadAction<StyleVariant>) {
      state.styleVariant = payload;
    },
  },
});

export const {
  setIsLoading,
  setRegistrationResponse,
  setError,
  setDebugIdentifiers,
  setPaymentusFormConfig,
  setIframeMode,
  setIframeIdentifier,
  setTestIdName,
  setStyleType,
  setStyleVariant,
} = paymentSlice.actions;
const paymentStateReducer = paymentSlice.reducer;
export default paymentStateReducer;
