import React, { useContext, createContext, FC, ReactNode } from "react";
import {
  useCreatePaymentMethodRegistration,
  useCreatePaymentusToken,
  useCreatePaymentMethod,
  useHandleClients,
} from "./hooks";
import {
  RegistrationRequest,
  RegistrationResponse,
  SecureRequest,
  MethodSetupRequest,
} from "../../../types";
import { MessageType } from "@ftdr/payment-method-micro-frontend";

export type PaymentsMethodClientContextProps = {
  paymentsUrl: string;
  children: ReactNode;
};

export type PaymentMethodClientContextValue = {
  createPaymentMethodRegistration: (
    requestData: RegistrationRequest
  ) => Promise<RegistrationResponse | undefined>;
  createPaymentusToken: (
    requestData: SecureRequest
  ) => Promise<MessageType | undefined>;
  createPaymentMethod: (
    requestData: MethodSetupRequest
  ) => Promise<MessageType | undefined>;
};

export const PaymentMethodClientContextInitialState: PaymentMethodClientContextValue =
  {
    createPaymentMethodRegistration: () => Promise.resolve(undefined),
    createPaymentusToken: () => Promise.resolve(undefined),
    createPaymentMethod: () => Promise.resolve(undefined),
  };

const PaymentMethodContext = createContext<PaymentMethodClientContextValue>(
  PaymentMethodClientContextInitialState
);

export const { Provider: PaymentMethodClientContextProvider } =
  PaymentMethodContext;

export const PaymentMethodClientContext: FC<
  PaymentsMethodClientContextProps
> = ({ paymentsUrl, children }) => {
  const { paymentMethodCoordinatorClient, paymentusSecureClient } =
    useHandleClients(paymentsUrl);

  const createPaymentMethodRegistration = useCreatePaymentMethodRegistration(
    paymentMethodCoordinatorClient,
    paymentusSecureClient
  );
  const createPaymentusToken = useCreatePaymentusToken(paymentusSecureClient);
  const createPaymentMethod = useCreatePaymentMethod(
    paymentMethodCoordinatorClient
  );

  return (
    <PaymentMethodClientContextProvider
      value={{
        createPaymentMethodRegistration,
        createPaymentusToken,
        createPaymentMethod,
      }}
    >
      {children}
    </PaymentMethodClientContextProvider>
  );
};
export const usePaymentMethodClientContext = () =>
  useContext(PaymentMethodContext);
