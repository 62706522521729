import React from "react";
import classNames from "classnames";
import { IconFormWarning } from "@ftdr/blueprint-components-react";
import { StyleVariant } from "../types";

type NotificationProps = {
  message?: string;
  dataTestId?: string;
  className?: string;
  status?: "error";
  styleVariant?: StyleVariant;
};

export const NativeNotification = ({
  message,
  dataTestId,
  status,
  className,
  styleVariant,
}: NotificationProps): JSX.Element => {
  return (
    <div
      data-testid={dataTestId}
      className={classNames(className, "NativeNotification", {
        ErrorNativeNotification: status === "error",
      })}
    >
      {styleVariant === "mobileAHS" && status === "error" && (
        <IconFormWarning className={"mr-1"} />
      )}
      {message}
    </div>
  );
};
