import "../assets/paymentus-input.css";
import * as useIframeInitializationHooks from "./use-iframe-initialization-hooks";
import { RefObject } from "react";
import { PaymentusFormRef } from "../form/paymentus-form";
import { PaymentRootState } from "../config/store";
import { useSelector } from "react-redux";

const HOST_MODES_MAP: Record<any, keyof typeof useIframeInitializationHooks> = {
  native: "useReactNativeInitialization",
  web: "useReactWebInitialization",
};

export type UsePaymnetusHostedIframeReturn = {
  paymentusFormRef: RefObject<PaymentusFormRef>;
  loading?: boolean;
};

export const usePaymentusHostedIframe = (): UsePaymnetusHostedIframeReturn => {
  const iframeMode = useSelector(
    (state: PaymentRootState) => state.paymentState.iframeMode
  );
  return useIframeInitializationHooks[HOST_MODES_MAP[iframeMode]]();
};
