import React from "react";
import classNames from "classnames";
import {
  FormFieldLabel,
  IconFormSuccess,
  Input,
  useAppContext,
} from "@ftdr/blueprint-components-react";
import { PaymentState } from "../../../config/store";
import { GenericInputProp, nameMap } from "../generic-input";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { FieldValues, useFormContext } from "react-hook-form";
import { PaymentusSetupItemTypeName } from "@ftdr/payment-method-micro-frontend";
import { PaymentusFormInputs } from "../../../types";
import { DefaultPaymentusStyles } from "../../../const";
import { removeNonDigits } from "../../utils";

export type TextInputProp = Partial<GenericInputProp> &
  ControllerRenderProps<FieldValues, PaymentusSetupItemTypeName> &
  Partial<PaymentState>;

export const TextInput = ({
  name,
  label,
  placeholder,
  value,
  testIdName,
  styleVariant = "",
  isNative,
  onChange,
  ...props
}: TextInputProp) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const {
    formState: { errors },
  } = useFormContext<PaymentusFormInputs>();

  return (
    <div
      data-styleid="PaymentusTextInputContainer"
      className={classNames(
        "",
        DefaultPaymentusStyles[styleVariant].PaymentusTextInputContainer,
        "paymentusTextInputContainer"
      )}
    >
      <div className={"flex flex-col grow"}>
        <FormFieldLabel
          id={`${name}Id`}
          formFieldId={`${name}FormFieldId`}
          required={styleVariant !== "mobileFTDR"}
          label={label || localizedText(nameMap[name])}
          className={classNames("flex FormFieldLabel")}
          labelRequiredIndicatorClassName={
            "LabelRequiredIndicatorClassName pr-1"
          }
          labelTextClassName={classNames(
            DefaultPaymentusStyles[styleVariant].TextLabel,
            "textLabel"
          )}
          labelInfoClassName={""}
        />
        <Input
          //only for RN id and label become appium locators
          {...(isNative && { id: `${testIdName}:PaymentusIframe:${name}` })}
          label={isNative ? `${testIdName}:PaymentusIframe:${name}` : ""}
          {...props}
          name={name}
          {...(name === "bankAccountNumberConfirmation" && {
            rootProps: { onPaste: (e) => e.preventDefault() },
          })}
          onChange={(e) => onChange(removeNonDigits(e?.target?.value))}
          value={value}
          hideLabel={true}
          formFieldClassName={classNames(
            DefaultPaymentusStyles[styleVariant].FormField,
            "formField"
          )}
          inputClassName={classNames(
            DefaultPaymentusStyles[styleVariant].InputElement,
            "inputElement"
          )}
          formField
          type="text"
          inputMode="numeric"
          placeholder={placeholder || "00000000000"}
          data-testid={`Payment__Type__Paymnetus__${name}`}
          error={localizedText(errors[name]?.message || "")}
        />
      </div>
      {styleVariant === "mobileFTDR" && value && !errors[name] && (
        <IconFormSuccess color="success" />
      )}
    </div>
  );
};
