import { useCallback } from "react";
import { useAppContext } from "@ftdr/blueprint-components-react";
import { PaymentRootState, setError, useAppDispatch } from "../../config/store";
import { MessageType } from "@ftdr/payment-method-micro-frontend";
import { HostModesValue, TealiumMessage } from "../../types";
import { useSelector } from "react-redux";

export const useSendMessageToParent = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const dispatch = useAppDispatch();
  const iframeMode = useSelector(
    (state: PaymentRootState) => state.paymentState.iframeMode
  );

  const handleSendMessageError = useCallback(() => {
    dispatch(
      setError({ isError: true, message: localizedText(`SEND_MESSAGE_ERROR`) })
    );
  }, [dispatch, localizedText]);

  const sendMessageToParent = useCallback(
    (message: MessageType | TealiumMessage | undefined | any) => {
      if (message) {
        try {
          if (iframeMode === HostModesValue.WEB) {
            window?.parentIFrame?.sendMessage(message);
          } else {
            window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
          }
        } catch {
          handleSendMessageError();
        }
      } else {
        handleSendMessageError();
      }
    },
    [iframeMode, handleSendMessageError]
  );

  return { sendMessageToParent };
};
