import { useCallback } from "react";
import { Payment3_paymentus_secureJSClient } from "@ftdr/payment3_paymentus_secure-js-client";
import {
  MESSAGE_STATUS,
  MessageType,
} from "@ftdr/payment-method-micro-frontend";
import { SecureRequest } from "../../../../types";
import { decodeCommonError } from "../../../../utils";
import { useSelector } from "react-redux";
import { PaymentRootState } from "../../../store";

export const useCreatePaymentusToken = (
  client: Payment3_paymentus_secureJSClient | undefined
): ((requestData: SecureRequest) => Promise<MessageType>) => {
  const debugIdentifiers = useSelector(
    (state: PaymentRootState) => state.paymentState.debugIdentifiers
  );

  const createPaymentusToken = useCallback(
    async (requestData: SecureRequest) => {
      let result = undefined;
      try {
        result = await client?.createPaymentusToken(requestData);
        return {
          status: MESSAGE_STATUS.PAYMENT_SECURE_SERVICE_SUCCESS,
          data: {
            payment_secure_service_data: result?.data,
          },
        };
      } catch (ex: any) {
        const decodedError = decodeCommonError(ex);
        const errorStatusCode = ex?.lastError?.response?.status;

        return {
          status: MESSAGE_STATUS.PAYMENT_SECURE_SERVICE_ERROR,
          error: {
            payment_secure_service_error: {
              decodedError: decodedError,
              rawError: ex,
              code: errorStatusCode,
              uuid: debugIdentifiers,
            },
          },
        };
      }
    },
    [client, debugIdentifiers]
  );

  return createPaymentusToken;
};
