import React, { FC } from "react";
import { HostModesValue } from "../types";
import { useResizeIframe } from "./use-resize-iframe";
import { useSelector } from "react-redux";
import { PaymentRootState } from "../config/store";

export const IframeResizer: FC = ({ children }) => {
  const storeIframeMode = useSelector(
    (state: PaymentRootState) => state.paymentState.iframeMode
  );
  const iframeDivRef = useResizeIframe();

  return (
    <div
      {...{
        className: "flex justify-center flex-col",
        ...(storeIframeMode === HostModesValue.NATIVE && {
          ref: iframeDivRef,
        }),
      }}
    >
      {children}
    </div>
  );
};
