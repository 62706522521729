import jwt_decode from "jwt-decode";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useTokenSemanticValidation = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  return useMemo((): boolean => {
    try {
      jwt_decode(token);
      return true;
    } catch (err) {
      return false;
    }
  }, [token]);
};
