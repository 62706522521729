type ValidationTypes =
  | "validate"
  | "required"
  | "minLength"
  | "maxLength"
  | "onlyDigits"
  | "confirm";

export const routingNumberError: Partial<Record<ValidationTypes, string>> = {
  validate: "ROUTING_NUMBER_ERROR",
  required: "REQUIRED_FILED_ERROR",
  minLength: "ROUTING_NUMBER_LENGTH_ERROR",
  maxLength: "ROUTING_NUMBER_LENGTH_ERROR",
  onlyDigits: "ROUTING_NUMBER_DIGITS_ERROR",
};

export const accountNumberError: Partial<Record<ValidationTypes, string>> = {
  validate: "ACCOUNT_NUMBER_ERROR",
  required: "REQUIRED_FILED_ERROR",
  minLength: "ACCOUNT_NUMBER_LENGTH_ERROR",
  maxLength: "ACCOUNT_NUMBER_LENGTH_ERROR",
  onlyDigits: "ACCOUNT_NUMBER_DIGITS_ERROR",
};

export const accountNumberConfirmationError: Partial<
  Record<ValidationTypes, string>
> = {
  confirm: "ACCOUNT_NUMBER_CONFIRMATION_ERROR",
  required: "REQUIRED_FILED_ERROR",
  onlyDigits: "ACCOUNT_NUMBER_CONFIRMATION_DIGITS_ERROR",
};
