import { TEALIUM_URL_MAP } from "../../const";

// modified from https://docs.tealium.com/platforms/javascript/single-page-applications/
window.utag_cfg_ovrd = {
  noview: true,
};

// window.utag_data = {
//variables to be populated
// };

export const loadTealium = function (a?: any, b?: any, c?: any, d?: any) {
  console.log("window.location.hostname", window.location.hostname);
  a = TEALIUM_URL_MAP[window.location.hostname] || "";
  b = document;
  c = "script";
  d = b.createElement(c);
  d.src = a;
  d.type = "text/java" + c;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);
};

export const loadTealiumScript = async (): Promise<Event | void> => {
  return new Promise((resolve, reject) => {
    if (window?.utag) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.src = TEALIUM_URL_MAP[window.location.hostname] || "";
      script.type = "text/javascript";
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode?.insertBefore(script, firstScript);
    }
  });
};
// place once the page is fully loaded
export const startTealium = () => {
  // eslint-disable-next-line no-restricted-globals
  addEventListener("load", () => {
    if (window?.utag) {
      //avoid throwing error if tealium isn't loaded for local development
      window?.utag?.view &&
        window?.utag?.view({
          virtual_page_path: window.location.hostname,
          virtual_page_URL: window.location.pathname,
        });
    }
  });
};
