import { useCallback } from "react";
import {
  MESSAGE_STATUS,
  MessageType,
} from "@ftdr/payment-method-micro-frontend";
import { PaymentMethodClient, MethodSetupRequest } from "../../../../types";
import { PaymentRootState } from "../../../store";
import { useAppContext } from "@ftdr/blueprint-components-react";
import { decodeCommonError, useSendMessageToParent } from "../../../../utils";
import { useSelector } from "react-redux";
import { CREATE_PAYMENT_METHOD_API_ERRORS } from "../../../../const";
import { useIsTealiumLoaded } from "./use-is-tealium-loaded";

export const useCreatePaymentMethod = (
  client: PaymentMethodClient | undefined
): ((requestData: MethodSetupRequest) => Promise<MessageType>) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const debugIdentifiers = useSelector(
    (state: PaymentRootState) => state.paymentState.debugIdentifiers
  );

  const tealiumIsLoaded = useIsTealiumLoaded();
  const { sendMessageToParent } = useSendMessageToParent();

  const createPaymentMethod = useCallback(
    async (requestData: MethodSetupRequest) => {
      let result = undefined;
      try {
        result = await client?.createPaymentMethod(requestData);

        if (tealiumIsLoaded) {
          sendMessageToParent({
            tealium_event: "tokenization complete",
            api_response_status: result?.status,
            event_timestamp: Date.now(),
          });
        }
        return {
          status: MESSAGE_STATUS.CREATE_PAYMENT_METHOD_SUCCESS,
          data: {
            create_payment_method_data: result?.data,
          },
        };
      } catch (ex: any) {
        const decodedError = decodeCommonError(ex);
        const errorStatusCode = ex?.lastError?.response?.status;
        let errorMessage = "";

        if (errorStatusCode) {
          errorMessage = localizedText(`ERROR_STATUS_CODE_${errorStatusCode}`);
        } else {
          errorMessage =
            ex?.lastError?.message ||
            localizedText(`ERROR_STATUS_CODE_UNDEFINED`);
        }

        let errorResult = {
          status: MESSAGE_STATUS.CREATE_PAYMENT_METHOD_ERROR,
          error: {
            create_payment_method_error: {
              message: errorMessage,
              decodedError: decodedError,
              rawError: ex,
              uuid: debugIdentifiers,
            },
          },
        };

        switch (errorStatusCode) {
          case 404:
            if (
              decodedError!.find(
                ({ code }) =>
                  code ===
                  CREATE_PAYMENT_METHOD_API_ERRORS.SETUP_INTENT_NOT_FOUND
              )
            ) {
              errorResult = {
                ...errorResult,
                status: MESSAGE_STATUS.CREATE_PAYMENT_METHOD_REDIS_CACHE_ERROR,
              };
            }
            break;
        }

        if (tealiumIsLoaded) {
          sendMessageToParent({
            tealium_event: "tokenization complete",
            api_response_status: errorStatusCode,
            event_timestamp: Date.now(),
          });
        }

        return errorResult;
      }
    },
    [
      client,
      localizedText,
      debugIdentifiers,
      sendMessageToParent,
      tealiumIsLoaded,
    ]
  );

  return createPaymentMethod;
};
