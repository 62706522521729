import { useMemo } from "react";
import { ProtobufHttpClient, TokenHandlingHttpClient } from "@ftdr/http-utils";
import { useSearchParams } from "react-router-dom";
import {
  PaymentMethodCoordinatorJSClient,
  PaymentusSecureJSClient,
} from "../clients";

type UseHandleClients = {
  paymentMethodCoordinatorClient: PaymentMethodCoordinatorJSClient;
  paymentusSecureClient: PaymentusSecureJSClient;
};

export const useHandleClients = (paymentsUrl: string): UseHandleClients => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const tokenHttpClient = useMemo(() => {
    return new TokenHandlingHttpClient(token);
  }, [token]);

  const protobufHttpClient = useMemo(() => {
    return new ProtobufHttpClient(tokenHttpClient);
  }, [tokenHttpClient]);

  const paymentMethodCoordinatorClient = useMemo(() => {
    return new PaymentMethodCoordinatorJSClient(
      paymentsUrl,
      protobufHttpClient
    );
  }, [paymentsUrl, protobufHttpClient]);

  const paymentusSecureClient = useMemo(() => {
    return new PaymentusSecureJSClient(paymentsUrl, protobufHttpClient);
  }, [paymentsUrl, protobufHttpClient]);

  return { paymentMethodCoordinatorClient, paymentusSecureClient };
};
