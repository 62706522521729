export const loadJQueryScript = async (): Promise<Event | void> => {
  return new Promise((resolve, reject) => {
    if (
      document.querySelector(
        `script[src~="https://code.jquery.com/jquery-3.5.1.slim.min.js"]`
      )
    ) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.src = "https://code.jquery.com/jquery-3.5.1.slim.min.js";
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      script.id = "jquery";
      script.integrity = "sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs=";
      script.crossOrigin = "anonymous";
      document?.head.appendChild(script);
    }
  });
};
